@import "./_vars.scss";

@font-face {
  font-family: "Museo Sans";
  src: url("./fonts/museosans-regular.ttf");
}

@font-face {
  font-family: "Museo Sans";
  src: url("./fonts/museosans-medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Museo Sans";
  src: url("./fonts/museosans-bold.ttf");
  font-weight: 700;
}

* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Museo Sans', sans-serif;
  font-size: 2vmin;
  line-height: 2.2vmin;
  color: $text;
}

#root {
  width: 100%;
  height: 100%;
}

.ignite-icon {
  height: 1.75vmin;
  width: 1.85vmin;
  margin: 0;
  padding: 0;
}

.live-x-tag {
  background: darken($primary, 10%);
  font-size: 10px;
  color: #fff;
  text-transform: uppercase;
  display: inline-block;
  padding: .5px 2px;
  border-radius: 4px;
  margin: 0 2px 0 0;
  vertical-align: middle;
}

.bookmark-tag {
  border: 1px solid $primary;
  font-size: 2vmin;
  padding: 2px;
  border-radius: 4px;
  margin: 0 6px 0 0;
  color: $primary;
  vertical-align: middle;
}

.live-x-set-tag {
  font-size: 10px;
  text-transform: uppercase;
  padding: 0;
  margin: 0 0 0 1vmin;
  color: #999;
  display: inline-block;
  vertical-align: middle;
}

.bmt-placeholder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;

  h1 {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 4vmin;
    line-height: 4vmin;
  }

  img {
    height: 10vmin;
    object-fit: contain;
    padding: 1.5vmin 2vmin;
    border-radius: 8px;
    box-shadow: 4px 8px 18px rgba(1,1,1,.1);
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.bmt-countdown {
  width: 50%;
  border: 2px solid #eee;
  border-radius: 14px;
  height: 10px;
  background: #fafafa;
  position: relative;
  overflow: hidden;
  margin: 1vmin 0;

  .bmt-countdown-fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: lighten($primary, 20%);
    transition: all 1.3s linear;
    transform-origin: 0% 0%;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

h1 {
  font-size: 2.6vmin;
  line-height: 2.7vmin;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 2.5vmin;
  line-height: 2.7vmin;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

h3 {
  font-size: 2.2vmin;
  line-height: 2.3vmin;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

h4 {
  font-size: 2.1vmin;
  line-height: 2.2vmin;
  font-weight: 400;
  margin: 0;
  padding: 0;
}


h5 {
  font-size: 1.6vmin;
  line-height: 1.7vmin;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

h6 {
  font-size: 1.6vmin;
  line-height: 1.7vmin;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

input, select, textarea {
  background: transparent;
  border: none;
  font-family: inherit;
  font-size: 1.8vmin;
  line-height: 2vmin;
  border: 2px solid transparent;
  border-radius: 8px;

  &.error {
    border-color: red;
  }

  &:focus {
    border-color: $primary;
  }

  /*&:read-only {
    background: #fafafa;
    outline: none;
    border: none;
  }*/
}

a, button {
  background: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  font-weight: 500;
  margin: 0;
  padding: 0;
  font-size: 1.7vmin;
  line-height: 1.7vmin;

  &:disabled {
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
    opacity: .8;
  }

  &.active {
    border: 1px solid $primary;
    color: $primary;
    border-radius: 4px;
    padding: .2vmin;
  }
}

.no-items {
  color: $textLight;
  margin: 2vmin 0;
  
  &.center {
    text-align: center;
  }
}

.primary-btn {
  background: $primary;
  color: #fff; 
  box-shadow: 0 4px 8px rgba(1,1,1,.1);
  padding: 1vmin 2vmin;
  border-radius: 5px;
  margin: 0;

  &:disabled {
    background: lighten($border, 30%);
    opacity: .8;
    color: $textLight;
  }

  &.active {
    background: $save;
    opacity: 1;
    animation: glow 3s infinite;
  }
}

.export-btn {
  margin: 0;
  padding: 1vmin 2vmin;
  border-radius: 5px;
  background: $save;
  color: #fff;

  &:hover {
    opacity: .8;
  }
}

.admin-panel {
  background: $beige;
  padding: 2vmin 4vmin;
  margin: -2vmin 0 2vmin -4vmin;
  width: calc(100% + 8vmin);
  font-size: 1.75vmin;
}

.margin-top {
  margin: 2vmin 0 0;
  display: block;
}

.secondary-btn {
  background: darken($bg, 10%);
  color: $textLight !important; 
  box-shadow: 0 4px 8px rgba(1,1,1,.1);
  padding: 1vmin 2vmin;
  border-radius: 5px;
  margin: 0;
}

.outline-btn {
  border: 1px solid $primary;
  box-shadow: 0 4px 8px rgba(1,1,1,.1);
  padding: 1vmin 2vmin;
  border-radius: 5px;
  margin: 0;
  color: $primary;
  background: lighten($primary, 42%);

  &:disabled {
    opacity: 0.4;
  }

  &.less-padding {
    padding: 1vmin;
  }

  &.active {
    background: $primary;
    color: #fff;
  }
}

.new-btn {
  background: darken($bg, 20%);
  box-shadow: 0 4px 8px rgba(1,1,1,.1);
  padding: 1vmin 2vmin;
  border-radius: 5px;
  margin: 0;
}

.save-btn {
  background: $save;
}

.negative-btn {
  background: $red;
  color: $redText;
  padding: 1vmin 2vmin;
  border-radius: 5px;
  margin: 0 !important;
}

.text-btn {
  color: $primary;
  margin: 0 !important;
  padding: 0;
  font-weight: 700;

  &.margin-top {
    margin: 3vmin 0 0 !important;
  }

  &:disabled {
    opacity: .5;
  }
}

.cancel-btn {
  color: #999;
  margin: 0;
  padding: 1vmin 2vmin;
  border: 1px solid #ddd;
  border-radius: 5px;

  &:disabled {
    opacity: .3;
  }
}

.margin-left {
  margin: 0 0 0 2vmin !important;
}

.right-aligned {
  display: block;
  margin: 0 0 0 auto;
}

.error {
  color: #d15869;
  margin: 1vmin 0 0;
}

.red {
  color: #d15869;
}

.gray {
  color: $textLight !important;
}

.primary-color {
  color: $primary;
}

.save-bg {
  background: $save !important;
}

.hide {
  display: none !important;
}

.capitalize {
  text-transform: capitalize;
}

.form-group-toggle {
  float: right;
  margin: -1.5vmin 5vmin 0 0;
}

.bmt-wrapper {
  padding: 2vmin;
  background: #fff;
  border-radius: 8px;

  &.margin-top {
    margin: 3vmin 0 1vmin;
  }

  &.margin-bottom {
    margin: 0 0 2vmin;
  }

  &.no-pad {
    padding: 0;
  }

  .bmt-wrapper-subheader {
    display: flex;
    align-items: center;

    h2, h3 {
      padding: 1vmin 0;
    }

    .bmt-button-group {
      margin: 0 0 0 auto;

      .cancel-btn {
        margin: 0 2vmin 0 0;
      }

      .refresh-btn {
        background: transparent;
        border: 1px solid $primary;
        margin: 0 2vmin 0 0;
        color: $primary;

        span {
          font-size: 1.5vmin;
          line-height: 1.5vmin;
        }
      }
    }
  }
}

.bmt-button-group {
  &.modal-group {
    text-align: right;
    margin: 4vmin 0 0;

    .primary-btn {
      margin: 0 0 0 2vmin;
    }
  }
}

.bmt-app-container {
  background: #fff;
  min-height: 100%;

  &.flex {
    display: flex;
    flex-direction: row;
  }

  .bmt-main {
    padding: 2vmin 4vmin;
    flex: 1;
    background: $bg;
    min-height: 100vh;

    .bmt-main-view {
      display: flex;
      min-height: 100%;
      flex-direction: column;
    }
  }
}

.bmt-details {
  margin: 1vmin 0 0;
  display: flex;
  flex: 1;
  flex-direction: column;

  .bmt-details-top-bar {
    display: flex;
    position: relative;
    background: #fff;
    padding: 2vmin;
    border-radius: 8px;
    align-items: center;

    li {
      position: relative;
      margin: 0 1vmin 0 0;
      padding: 0 1vmin;
      outline: none;
      color: #aaa;

      &:not(.selected):hover {
        cursor: pointer;
        opacity: .8;
      }
      
      &.selected {
        color: $primary;
        font-weight: 700;
      }

      &.right-align {
        margin: 0 0 0 auto;
      }

      &.disabled {
        opacity: .5;
        pointer-events: none;
      }
    }
  }

  .bmt-details-main {
    margin: 1vmin 0 0;
    text-align: left;
    flex: 1;

    &.flex {
      display: flex;
    }

    .main-left {
      padding: 0 2vmin 0 0;
      margin: 0 2% 0 0;
      width: 38%;
    }

    .main-right {
      width: 60%;
    }

    .preview-only {
      width: 70%;
      margin: 0 auto;
    }
  }
}

.bmt-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9990;
  background: #fff;

  h1 {
    position: absolute;
    top: 37%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: $primary;
  }

  h3 {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .lds-ring {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    transform: translate(-50%,-50%);

    div {
      width: 64px;
      height: 64px;
      margin: 8px;
      border-width: 8px;
    }
  }
}

.bmt-popup-menu {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(1,1,1,.1);
  padding: 1vmin 2vmin;
  z-index: 8888;
  outline: none;

  button {
    font-size: 1.7vmin;
    line-height: 2vmin;
    margin: 1vmin 0;
    padding: 0;
    font-weight: 400;
    color: #666;
    display: block;
  }
}

.lds-ring {
  display: inline-block;
  width: 30px;
  height: 30px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  margin: 0;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $primary transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}