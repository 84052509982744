@import "../../_vars.scss";

.dropdown {
  width: 300px;
  margin: 1vmin 0 0;
  position: relative;

  &.expanded {
    .dropdown-main {
      border-color: $primary;
    }
  }

  .dropdown-main {
    display: flex;
    padding: 1vmin 2vmin;
    border-radius: 8px;
    border: 1px solid $border;
    transition: all .2s ease;
    
    h4 {
      flex: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 1.8vmin;
      line-height: 2vmin;
    }

    span {
      flex: initial;
      margin: 0 0 0 auto;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 2px 3px 6px rgba(1,1,1,.1);
    }
  }

  .dropdown-expanded {
    position: absolute;
    background: #fff;
    box-shadow: 2px 3px 6px rgba(1,1,1,.1);
    left: 0;
    bottom: 0;
    width: 100%;
    transform: translateY(110%);
    padding: 1vmin;
    border-radius: 4px;
    z-index: 8888;

    ul {
      margin: 0;
      padding: 0;

      li {
        margin: 1vmin 0;
        padding: 0;
        font-size: 1.6vmin;
        line-height: 2vmin;

        &:before {
          content: '';
          display: inline-block;
          width: 1vmin;
          height: 1vmin;
          border-radius: 2px;
          border: 1px solid $border;
          margin: 0 1vmin 0 0;
        }

        &:hover {
          cursor: pointer;
          color: $textLight;
        }

        &.selected {
          color: $primary;

          &:before {
            background: $primary;
            border-color: $primary;
          }
        }
      }
    }
  }
}