.presentation-content {
  h3 {
    margin: 0 0 3vmin;
  }

  h3:before {
    content: "";
    display: inline-block;
    width: 1.25vmin;
    height: 1.25vmin;
    border-radius: 100%;
    background: rgb(255, 0, 85);
    margin: 0 1vmin 0 0;
  }

  .presentation-content-slides {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .presentation-content-slide {
    width: 23.5%;
    margin: 4vmin 2% 2vmin 0;
    border: 2px solid #999;
    border-radius: 5px;
    padding-bottom: 30%;
    position: relative;

    &:nth-child(4n) {
      margin: 4vmin 0 2vmin 0;
    }

    &.active {
      border-color: rgb(255, 230, 0);
    }

    &:not(.active):hover {
      background: #222;
      cursor: pointer;
    }
  }

  .presentation-content-slide .slide-module {
    position: absolute;
    width: 60%;
    height: 60%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

    iframe {
      max-width: 100%;
      max-height: 100%;
      border: none;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      outline: none;
      pointer-events: none;
    }
  }
}