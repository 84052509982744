.reporting-meta {
  text-align: center;
  color: #999;
  margin: 2vmin 0;

  i {
    color: #333;
    font-style: normal;
    font-weight: 500;
  }
}

.reporting-flex-row {
  margin: 0 0 4vmin;
  display: flex;
}

.reporting-flex-col {
  flex: initial;
  width: 33.3%;
  padding-bottom: 20%;

  &:nth-child(2) {
    margin: 0 3vmin;
  }

  .recharts-responsive-container {
    min-height: 200px;
  }

  &.two {
    width: 50%;

    &:nth-child(1) {
      margin: 0 2vmin 0 0;
    }

    &:nth-child(2) {
      margin: 0 0 0 2vmin;
    }
  }
}

.user-list-view {
  width: 100%;

  .user-list-item {
    padding: 1vmin 0;
    display: flex;
    align-items: center;
    border-radius: 5px;

    &.more-padding {
      padding: 1.3vmin 0;
    }

    &.header-row {
      font-weight: 500;
      font-size: 1.75vmin;
    }

    &:not(.header-row):not(.no-action):hover {
      cursor: pointer;
      background-color: #fafafa;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }

    .user-item-avatar {
      flex: initial;
      width: 5vmin;

      span {
        height: 3vmin;
        width: 3vmin;
        border-radius: 100%;
        font-size: 1.25vmin;
        display: block;
        background: #eee;
        line-height: 3vmin;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .page-item-percentile {
      height: 3vmin;
      width: 6vmin;
      margin: 0 2vmin 0 0;
      position: relative;
      overflow: hidden;
      display: flex;
      border-radius: 2px;
      background: rgb(71, 191, 247);
      background: linear-gradient(45deg, rgb(71, 191, 247), rgb(113, 245, 234));

      &.no-display {
        background: transparent;
      }

      .page-item-percentile-bar {
        flex: initial;
        width: 10%;
        
        &.filled {
          background: rgb(240, 26, 115);
          background: linear-gradient(rgb(240, 26, 115), rgb(240, 26, 240));
        }
      }
    }

    .page-item-percentile-label {
      font-size: 1vmin;
      line-height: 1vmin;
      text-transform: uppercase;
      font-weight: 500;
      display: block;
      margin: 0;
      color: #555;

      sup {
        font-size: .6vmin;
      }
    }

    .user-item-meta {
      flex: 2;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 2vmin 0 0;
    }

    .user-item-count {
      flex: 0.75;
    }
  }
}

.reporting-full-row {
  margin: 0 0 4vmin;
  padding: 0;
}

.full-chart {
  height: 40vh;
  width: 100%;
}

.funnel-diagram {
  width: 60%;
  border: 1px dashed #ddd;
  position: relative;

  &:before {
    content: "";
    display: block;
    height: 100%;
    width: 1px;
    border-left: 1px dashed #ddd;
    position: absolute;
    left: 25%;
    top: 0;
  }

  &:after {
    content: "";
    display: block;
    height: 100%;
    width: 1px;
    border-left: 1px dashed #ddd;
    position: absolute;
    right: 25%;
    top: 0;
  }

  .funnel-middle {
    display: block;
    height: 100%;
    width: 1px;
    border-left: 1px dashed #ddd;
    position: absolute;
    right: 50%;
    top: 0;
  }
}

.funnel-item {
  height: 3vmin;
  position: relative;
  width: 100%;
  margin: 4vmin 0;
  z-index: 2;

  .funnel-item-fill {
    background: #3306d3;
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 3vmin;
    transform: translateX(-50%);
    border-radius: 6px;
    box-shadow: 2px 6px 8px rgba(1,1,1,.1);
  }

  h5 {
    position: absolute;
    top: 50%;
    left: 0;
    font-size: 2vmin;
    padding: 0;
    margin: 0;
    transform: translate(-60%, -250%);
    font-size: 1.75vmin;
    font-weight: 400;
    color: #666;

    span {
      color: #3306d3;
      font-weight: 700;
      display: inline-block;
      margin: 0 1vmin 0 0;
      padding: 0;
    }
  }

  .funnel-item-line {
    background: #bbb;
    background: linear-gradient(45deg, #3306d3, #cc06d3);
    height: 1px;
    position: absolute;
    top: 30%;
    left: 0;
    transform: rotate(3deg);

    &:after {
      content: "";
      display: block;
      width: 1vmin;
      height: 1vmin;
      background: #bbb;
      background: linear-gradient(45deg, #3306d3, #cc06d3);
      border-radius: 100%;
      border: 2px solid #cc06d3;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-50%,-50%);
    }
  }
}

.reporting-col {
  background: #fff;
  padding: 4vmin;
  border-radius: 12px;
  display: flex;
  align-items: center;

  &.col {
    flex-direction: column;
  }

  h4 {
    margin: 0 0 2vmin;
  }

  .reporting-col-header {
    display: flex;
    margin: 0 0 2vmin;
    width: 100%;

    h4 {
      margin: 0 auto;
      transform: translateX(25%);
    }

    .text-btn {
      margin: 0 0 0 auto;
    }
  }

  .data-icon {
    display: inline-block;
    flex: initial;
    padding: 3vmin;
    background: #e5f8fd;
    border-radius: 100%;
    color: #0f6af1;
    line-height: 1;

    &.green {
      background: rgb(113, 248, 207);
      background: linear-gradient(45deg, rgb(113, 248, 207), rgb(21, 150, 236));
      color: #fff;
    }
    
    &.data-turnout {
      position: relative;
      overflow: hidden;
      background: #030b75;

      &.invert {
        background: rgb(99, 15, 29);
      }
      
      .data-turnout-fill {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #4e4bd7;
        background: linear-gradient(45deg, #4e4bd7, #a85eda);

        &.invert {
          background: rgb(167, 25, 49);
          background: linear-gradient(45deg, rgb(167, 25, 96), rgb(238, 50, 81));
        }
      }

      .icon-users {
        position: relative;
        z-index: 1;
        color: #fff;
        display: inline-block;

        &.invert:after {
          content: "";
          display: block;
          width: 1px;
          height: 200%;
          background: #fff;
          position: absolute;
          top: -50%;
          left: 50%;
          transform: rotate(40deg);
        }
      }
    }
  }

  .data-row {
    margin: 0 0 0 2vmin;

    h2 {
      color: #333;
      font-size: 3vmin;
      margin: 0 0 0.5vmin;
      padding: 0;
      font-weight: 700;
    }

    h3 {
      color: #666;
      font-size: 1.75vmin;
      padding: 0;
      font-weight: 300;
      margin: 0;
    }
  }
}

.reporting-flex-col-container {
  flex: initial;
  width: 33.3%;
  display: flex;
  flex-direction: column;

  .reporting-flex-split-col {
    flex: 1;
    border: 1px solid red;
  }
}

.report-detail-user-view {
  .bmt-details-top-bar {
    padding: 2vmin 0 !important;
  }
}

.no-data-found {
  color: #ccc;
}