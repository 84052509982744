@import "../../_vars.scss";

.bmt-header {
  display: flex;
  align-items: center;
  margin: 0 0 .5vmin;

  h1 {
    flex: 1;
    font-size: 2.75vmin;
    line-height: 2.75vmin;
    font-weight: 600;
    padding: 1vmin 0;
  }

  .top-left-btn {
    flex: initial;
    font-size: 2.2vmin;
    color: $textLight;
    font-weight: 400;
    margin: 0 4vmin 0 0;

    &:disabled {
      color: lighten($textLight, 20%);
    }

    span {
      font-size: 2vmin;
      vertical-align: top;
      display: inline-block;
      margin: 0 .5vmin 0 0;
    }
  }

  .top-right-btn {
    flex: initial;
    padding: 1vmin 2vmin;
    border-radius: 5px;
    margin: 0 0 0 2vmin;
  }

  .header-item {
    flex: initial;
    color: #999;
    font-size: 1.5vmin;
    line-height: 1.5vmin;
  }
}