@import "../../_vars";

.dashboard-container-capture-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  color: $primary;
  z-index: 8888;

  h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

.printable {
  .export-btn {
    display: none !important;
  }
}

.dashboard-container {
  display: flex;
  background: #fff;
  padding: 2vmin;
  margin: 2vmin 0;
  border-radius: 8px;
  flex-wrap: wrap;

  &.no-padding {
    padding: 0;
  }

  .vis-heat {
    display: inline-block;
    width: 1vmin;
    height: 1vmin;
    border-radius: 100%;
    margin: 0 0.5vmin 0 0;
  }

  .dashboard-vis {
    height: 30vmin;
    width: 50%;
    padding: 0 0 2vmin;
    margin: 0 0 4vmin;

    &.full {
      width: 100%;
      height: 40vmin;
    }

    &.list {
      height: auto;
      width: 100%;
    }

    .dashboard-vis-header {
      display: flex;
      margin: 2vmin 0 0;

      .export-btn {
        flex: initial;
        margin: 0 0 0 auto;
        color: $primary;
        font-weight: 600;
      }
    }

    h4 {
      font-size: 1.75vmin;

      span {
        padding: .5vmin 1vmin;
        background: darken($primary, 20%);
        color: #fff;
        border-radius: 4px;
      }
    }

    .vis-tooltip {
      background: #222; 
      border: none;
      border-radius: 8px; 
      padding: 1vmin;
      box-shadow: 2px 4px 12px rgba(1,1,1,.2);
      color: #fff;
      margin: 0;

      h3, h4 {
        padding: 0;
        margin: 0;
      }

      h3 {
        font-style: italic;
        font-size: 1.6vmin;
      }

      h4 {
        font-size: 1.5vmin;
      }
    }
  }
}
