.bmt-mini-loader {

  &.inline {
    display: inline-block;
    margin: 0 2vmin 0 0;
    position: relative !important;
    top: auto !important;
    left: auto !important;
    transform: none !important;
  }

  .lds-ring {
    vertical-align: middle;
  }

  h3 {
    display: inline-block;
    font-size: 1.6vmin;
    line-height: 1.6vmin;
    font-weight: 700;
    color: #222;
    padding: 0;
    margin: 0 0 0 1vmin;
    animation: fadeInOut 3s ease infinite;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}