@import "../../_vars";

.bmt-sidebar {
  width: 20%;
  background: #fff;
  padding: 2vmin 3vmin;
  margin: 0;
  position: relative;

  // &.dark {
    // background: $primary;  
    // background: linear-gradient(40deg, #276ef1, $primary);
    // background: #fff;
    // color: #fff;

    // ul li.selected {
      // background: darken($primary, 20%);
    // }

    // .bmt-version {
    //   color: #ccc;
    // }

    // .bmt-branding {
    //   h2 {
    //     color: #fff;
    //   }

    //   .sidebar-toggle-btn {
    //     color: #fff;
    //   }
    // }

    // .bmt-user-container {
    //   .bmt-user {
    //     h3 {
    //       color: #ccc;
    //     }

    //     .user-menu-btn {
    //       color: #ccc;
    //     }
    //   }
    // }
  // }

  &.collapsed {
    width: 5%;
    padding: 2vmin 0;

    .bmt-user-container {
      .bmt-user {
        display: none;
      }
    }

    ul {
      margin: 0;
      text-align: center;
    }

    .bmt-version {
      margin: 0;
      text-align: center;

      &.program-updates {
        display: none;
      }
    }

    .bmt-test {
      margin: 4vmin 0 2vmin;
      text-align: center;

      span {
        display: none;
      }

      i {
        display: inline-block;
        background: $red;
        color: $redText;
        font-style: normal;
        padding: .2vmin .4vmin;
        border-radius: 4px;
      }
    }

    .bmt-branding {
      flex-direction: column;

      h2 {
        display: none;
      }

      .sidebar-toggle-btn {
        margin: 2vmin 0;
      }
    }
  }

  ul {
    margin: 4vmin 0 0;
    padding: 0;

    li {
      font-size: 1.8vmin;
      line-height: 2vmin;
      padding: 0;
      margin: 0 0 1vmin;
      border-radius: 4px;
      font-weight: 400;
      outline: none;

      a {
        display: block;
        padding: .6vmin 1vmin;
      }
      
      &.selected {
        background: #eee;
        font-weight: 500;
      }

      &:not(.selected):hover {
        cursor: pointer;
        opacity: .8;
      }
    }
  }

  .bmt-version {
    margin: 4vmin 0 0;
    font-size: 1.5vmin;
    color: #999;
    text-align: right;
    position: relative;

    .version-hidden {
      color: #333;
      text-align: center;
      visibility: hidden;
    }

    &:hover  {
      cursor: pointer;

      .version-hidden {
        visibility: visible;
      }
    }
  }

  .bmt-test {
    margin: 4vmin 0 0;
    font-size: 1.5vmin;
    text-transform: uppercase;
    text-align: right;
    font-weight: bold;

    span {
      background: $red;
      padding: .5vmin;
      border-radius: 4px;
      color: $redText;
    }

    i {
      display: none;
    }
  }

  .bmt-branding {
    display: flex;
    align-items: center;

    @media only screen and (max-width: 1024px) {
      h2 {
        display: none;
      }
    }

    .bmt-icon {
      overflow: hidden;
      height: 4.5vmin;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(1,1,1,.2);
      display: block;
      margin: 0;
      padding: .5vmin;
    }

    h2 {
      font-size: 2vmin;
      line-height: 2.2vmin;
      font-weight: 500;
      color: #000;
      margin: 0 4vmin 0 1vmin;
      padding: 0;
      flex: 1;
    }

    .sidebar-toggle-btn {
      margin: 0 0 0 auto;
      font-size: 2vmin;
      color: #333;
      padding: 0;
      transition: all .3s ease;

      &:hover {
        background: #fafafa;
        color: #999;
      }
    }
  }

  .bmt-user-container {
    margin: 4vmin 0 0;

    .bmt-user {
      display: flex;
      position: relative;
      outline: none;

      @media only screen and (max-width: 1024px) {
        flex-direction: column;

        .user-menu-btn {
          margin: .5vmin auto 0 0 !important;
        }
      }

      h3 {
        font-size: 1.6vmin;
        line-height: 1.8vmin;
        font-weight: 500;
        color: #666;
      }

      .user-menu-btn {
        margin: 0 0 0 auto;
        font-size: 2.2vmin;
        line-height: 1.2vmin;
        color: #333;
      }

      .user-label {
        background: $secondary;
        font-size: 1vmin;
        line-height: 1vmin;
        font-weight: 400;
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        padding: .4vmin;
        color: $text;
        border-radius: 4px;
        text-transform: uppercase;
      }
    }
  }
}