@import "../../_vars.scss";

.bmt-login {
  background: #fff;
  min-height: 100vh;
  width: 100%;
  display: flex;
  cursor: default;

  .bmt-login-main {
    padding: 4vmin 15%;
    width: 50%;
  }

  .bmt-login-right {
    width: 50%;
    background: $primary;
    background: linear-gradient(-30deg, #276ef1, $primary);
    height: 100%;
  }

  h2 {
    margin: 4vmin 0 4vmin;
    font-size: 150%;
  }

  h4 {
    margin: 2vmin 0 0;
    font-weight: 500;
    font-size: 90%;
  }

  .bmt-branding {
    display: flex;
    align-items: center;

    img {
      height: 6vmin;
      display: block;
      margin: 0;
      padding: .6vmin;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(1,1,1,.2);
    }

    h3 {
      margin: 0 0 0 1vmin;
      font-weight: 500;
    }
  }

  input {
    display: block;
    margin: 1vmin 0 4vmin;
    padding: 1vmin 2vmin;
    border-radius: 5px;
    border: 1px solid #999;
    width: 100%;
  }

  .primary-btn {
    margin: 1vmin auto 0;
    width: 100%;
  }
}