.bmt-previewer {
  &.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 9991;
    padding: 5vmin;
    overflow-y: auto;
    
    .previewer-output {
      max-width: 80%;
      margin: 0 auto;
    }
  }

  &.ignite {
    padding: 5vmin 5vmin 0;
  }

  .ignite-disabled {
    .ignite-header, .ignite-footer, .ignite-sidebar {
      display: none;
    }
  }

  .ignite-preview {
    position: relative;
    left: -5vmin;
    width: calc(100% + 10vmin);
    min-height: 100vh;

    .ignite-container {
      display: flex;
      flex-direction: row;
      min-height: 100%;
    }

    .ignite-header, .ignite-footer, .ignite-sidebar {
      display: block;
    }

    .ignite-landing {
      flex: 1;
    }

    .previewer-output {
      max-width: none;
      padding: 2vh 2vw;
    }
  }

  .ignite-header {
    height: 55px;
    background: #1e5194;
    color: #fff;
    box-shadow: 2px 2px 4px rgba(0,0,0,.3);
    padding: 0 0 0 320px;
    position: relative;
    z-index: 999;

    h4 {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .ignite-footer {
    height: 60px;
    border-top: 1px solid #e4eaec;
    padding: 20px;
    width: 100%;
  }

  .ignite-sidebar {
    width: 280px;
    flex: initial;
    min-height: 100%;
    background: #3c5a81;
  }

  .previewer-header {
    display: flex;
    margin: 0 0 2vmin;

    h2 {
      flex: 1;
    }

    .previewer-header-options {
      flex: initial;

      button {
        background: #eee;
        border-radius: 4px;
        font-size: 2vmin;
        line-height: 2vmin;
        padding: 1vmin;
        color: #999;
        margin: 0 0 0 2vmin;
      }
    }
  }
  
  .bmt-mini-loader {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    background: #fff;
    padding: 1vmin 2vmin;
    border-radius: 8px;
    box-shadow: 4px 8px 12px rgba(1,1,1,.1);
  }

  .previewer-container {
    position: relative;
  }

  .previewer-output {
    position: relative;
    transition: all 1s ease;

    &.hidden {
      opacity: 0;
      transform: scale(.95) translateY(5vmin);
    }
  }
}