/* Colors */
/*$primary: #314CD4;*/
$secondary: #D5EDF9;
$save: #4ad493;
$saveText: #00391e;
$bg: #f4f4f5;
$text: #292929;
$textLight: #757575;
$disabled: #D6D6D6;
$border: rgb(145, 148, 166);
$red: rgb(255, 235, 240);
$redText: #c8264f;
$yellow: #FBD800;
$orange: #F77600;
$beige: #fffaea;
$lightBlue: #46D4FF;
/*$purple: #612EFF;*/
$primary: #612EFF;

/* Animations */
@keyframes glow {
  0% {
    background: $save;
    color: $textLight;
  }
  50% {
    background: lighten($save, 20%);
    color: lighten($textLight, 30%);
  }
  100% {
    background: $save;
    color: $textLight;
  }
}

/* Statuses */
.status-tag.active:hover {
  cursor: pointer;
  opacity: .9;
}

.status-tag span {
  border-radius: 15px;
  padding: .5vmin 1vmin;
  font-weight: 500;
  white-space: nowrap;
  font-size: 1.5vmin;
}

.status-published span, .status-available span {
  background: $primary;
  color: #fff;
}

.status-publishing span, .status-pending span {
  background: $yellow;
}

.status-pending-submission span, .status-submitted span {
  background: $orange;
  color: #fff;
}

.status-published-overwritten span {
  border: 1px dashed $save;
  background: lighten($save, 40%);
}

.status-failed-to span, .status-no-data-available span {
  border: 1px solid $redText;
  background: lighten($redText, 15%);
  color: #fff;
}

.status-created span, .status-draft span {
  background: $lightBlue;
}

.status-deploying span{
  background: lighten(#8885d8, 20%);
}

.version-count {
  display: inline-block;
  font-size: 1.5vmin;
  line-height: 1.5vmin;
  border-radius: 3px;
  padding: .3vmin .6vmin;
  background: lighten($lightBlue, 20%);
  color: $textLight;
  font-weight: 400;
  text-align: center;
  margin: 0 0 0 .2vmin;

  &.green {
    background: lighten($primary, 20%);
    color: #fff;
  }

  &.red {
    background: lighten($orange, 20%);
    color: #fff;
  }

  &.dashed-green {
    border: 1px dashed $save;
    background: lighten($save, 40%);
  }

  &.dashed-red {
    background: $red;
    border: 1px solid $redText;
  }

  &.blue {
    background: lighten($yellow, 20%);
  }
}

/* Color bgs */
.orange-bg {
  background: lighten($orange, 10%) !important;
}

/* Colors */
.primary-color {
  color: $primary;
}