@import "../_vars";

/* Special classes */
.prefix-url {
  position: relative;

  input {
    padding-left: 7vmin !important;
    width: 100% !important;
    margin: 1vmin 0 0 !important;
  }

  .prefix-url-hint {
    position: absolute;
    top: calc(50% + .5vmin);
    left: .75vmin;
    z-index: 2;
    font-size: 1.5vmin;
    line-height: 1.5vmin;
    background: #666;
    border-radius: 4px;
    padding: .4vmin;
    color: #fff;
    display: inline-block;
    margin: 0;
    transform: translateY(-50%);
  }
}

.form-set-list {
  margin: 1vmin 0 2vmin;

  &.divider {
    border-bottom: 1px solid #eee;
    padding: 0 0 2vmin;
  }
}

.form-set {
  display: flex;
  margin: 1vmin 0 2vmin;

  &.non-flex {
    display: block;
  }

  &.divider {
    border-bottom: 1px solid #eee;
    padding: 0 0 2vmin;
  }

  .form-group {
    flex: 1;

    &.half {
      button {
        flex: initial;
        margin: 0;
      }
    }

    &:nth-of-type(1) {
      margin: 0 2vmin 0 0;
    }

    &:nth-of-type(2) {
      margin: 0 0 0 2vmin;
    }
  }
}

.set-group {
  position: relative;
  overflow: hidden;

  .disabled-overlay {
    background: rgba(255,255,255,.9);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 8888;
  }
}

.bmt-preset-picker {
  margin: 2vmin 0;

  button {
    margin: 0 2vmin 0 0 !important;
  }

  a {
    color: $textLight;
  }
}

.grid-input {

  .grid-input-header {
    display: flex;
    padding: 1vmin 0;

    h4 {
      font-size: 1.75vmin;
      color: #999;
      flex: initial;
    }

    .add-btn {
      flex: initial;
      margin: 0 0 0 auto;
      border: 1px solid $save;
      padding: .6vmin 1vmin;
      border-radius: 5px;
      color: darken($save, 5%);
    }
  }

  .grid-item {
    background: lighten($bg, 2%);
    border-radius: 5px;
    padding: 1vmin;
    margin: 0 0 1vmin;

    .form-group {
      margin: 0;
      padding: 0;
    }
  }
}

.form-textarea {
  margin: 1vmin 0;

  .tox-tinymce {
    margin: 1vmin 0;
    border: 1px solid $border;
    border-radius: 8px;
  }

  textarea {
    border: 1px solid $border;
    margin: 1vmin 0;
    width: 100%;
    resize: vertical;
    padding: 1vmin;
  }
}

.form-group {
  margin: 2vmin 0;
  padding: 1vmin 0;
  display: flex;
  align-items: center;
  overflow: hidden;

  &.overflow {
    overflow: visible;
  }

  &.border {
    border-bottom: 1px solid #eee;
  }

  &.non-flex {
    display: block;

    input, textarea, select {
      margin: 1vmin 0 0;
      width: 100%;
    }
  }

  &.half {
    input {
      width: 50%;
    }
  }

  h5 {
    width: 50%;
  }

  button {
    flex: .5;
    margin: 0 0 0 2vmin;
    white-space: nowrap;

    &.secondary-btn {
      margin: 1vmin 0;
    }
  }

  .multi-text-list {
    margin: 1vmin 0;
    padding: 0.5vmin 0.5vmin 0.5vmin 0;
    border-radius: 5px;

    .delete-btn {
      color: $redText;
      font-size: 1.5vmin;
    }

    &.dragover {
      background: #efefef;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      color: #666;
      cursor: move;
      margin: 0 1vmin 0 0;
    }
  }

  .form-group-row {
    display: flex;
    margin: 2vmin 0 0;

    .form-group-row-item {
      flex: initial;
      margin: 0 4vmin 0 0;
    }

    .form-group-btn {
      margin: 0 0 0 auto;
      flex: initial;
      align-self: flex-end;
      position: relative;

      i {
        position: absolute;
        top: -.5vmin;
        right: -.5vmin;
        font-size: 1vmin;
        font-style: normal;
        background: darken($red, 30%);
        height: 1.5vmin;
        width: 1.5vmin;
        border-radius: 100%;
        color: #fff;
      }
    }
  }

  .form-group-subheader {
    display: flex;
    margin: 1vmin 0 0;
    position: relative;
    
    div {
      flex: initial;
      width: 80%;
    }

    input {
      margin: 0 2vmin 0 0;
      width: 62.5%;
    }

    button {
      flex: initial;
      margin: 0 0 0 auto;
    }

    .refresh-btn {
      margin: 0 2vmin 0 0;
      color: $primary;
      font-weight: bold;

      .last-checked {
        color: #ccc;
        font-weight: 500;
        font-size: 1.25vmin;
      }
    }

    .form-group-modal {
      position: absolute;
      top: 0;
      right: 0;
      border: 1px solid $border;
      background: #fff;
      border-radius: 5px;
      width: 300px;
      padding: 1vmin;
      box-shadow: 0 4px 8px rgba(1,1,1,.1);
      z-index: 8888;

      ul {
        padding: 0;
        margin: 0;

        li {
          margin: .5vmin 0;
          font-size: 1.75vmin;
          
          button {
            color: $textLight;
            font-weight: normal;
          }

          &:before {
            content: "";
            height: 1vmin;
            width: 1vmin;
            border: 1px solid #eee;
            border-radius: 2px;
            display: inline-block;
            margin: 0 1vmin 0 0;
          }

          &.selected {
            
            button {
              font-weight: 500;
              color: $primary;
            }

            &:before {
              background: $primary;
              border-color: $primary;
            }
          }
        }
      }
    }
  }

  .stacked-check-inputs {
    flex: 3;

    .bmt-check-input {
      display: block;
    }
  }

  input, select, h6, div, textarea {
    flex: 3;
    outline: none;

    &.smaller {
      flex: 1;
      margin: 0 0 0 2vmin;
    }

    &.invalid {
      border: 1px solid $redText;
      background: $red;
    }
  }

  input, textarea {
    background: transparent;
    border: 1px solid $border;
    padding: 1vmin 2vmin;
  }

  select {
    border-radius: 8px;
    border: 1px solid $border;
    padding: 1vmin 2vmin;
  }
}

.hex-picker {
  display: flex;
  align-items: center;

  .hex-preview {
    flex: initial;
    width: 3vmin;
    height: 3vmin;
    border-radius: 100%;
    border: 2px solid #ccc;
    margin: 1vmin 0 0 1vmin;
  }
}

.bool-picker {
  position: relative;
  background: #fafafa;
  border-radius: 20px;
  padding: .5vmin 4vmin;
  margin: 1vmin 0 0;
  border: 2px solid #eee;
  display: inline-block;
  color: #999;
  outline: none;

  &.mini {
    border: none;
    padding: .75vmin 3vmin;
    background: #efefef;

    &:before {
      height: 2.6vmin;
      width: 2.6vmin;
    }

    &.selected {
      background: $primary;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: .3vmin;
    left: .3vmin;
    height: 2vmin;
    width: 2vmin;
    border-radius: 100%;
    background: #fff;
    border: 2px solid #eee;
  }

  &:hover {
    cursor: pointer;
    opacity: .8;
  }

  &.disabled {
    opacity: .5;
    pointer-events: none;
  }

  &.selected {
    background: #2bbc63;
    color: #fff;

    &:before {
      left: auto;
      right: .3vmin;
    }
  }
}

.bmt-check-input {
  margin: 1vmin 0 0;
  color: $textLight;
  font-size: 90%;
  display: inline-block;

  &:before {
    content: '';
    height: 1.75vmin;
    width: 1.75vmin;
    border-radius: 3px;
    background: $bg;
    display: inline-block;
    vertical-align: middle;
    margin: 0 1vmin 0 0;
  }

  &:hover {
    cursor: pointer;
    opacity: .8;
  }

  &.selected {
    color: $save;

    &:before {
      background: $save;
    }
  }
}

.bmt-media-input {
  border: 1px solid $border;
  border-radius: 8px;
  padding-bottom: 50%;
  position: relative;
  margin: 1vmin 0;
  overflow: hidden;

  &.locked {
    opacity: .8;
    pointer-events: none;
  }

  &.bmt-flex-picker {
    flex: 2 !important;
    position: relative;
    padding: 2vmin 1vmin;
    margin: 0;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 90%;
    }
  }

  &.bmt-picker-input {
    padding: 2vmin 1vmin;

    .bmt-picker-input-top {
      display: flex;
      align-items: center;

      .top-label {
        width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      input {
        margin: 0;
        width: 70%;
      }

      h5 {
        width: 30%;
        flex: initial;
        margin: 0 2vmin 0 0;
      }

      a {
        font-size: 1.5vmin;

        i {
          color: $primary;
          vertical-align: middle;
          margin: 0 .2vmin .2vmin 0;
        }
      }
    }
  }

  &.disabled {
    pointer-events: none;
    background: darken($bg, 10%);
  }

  &.active {
    border-color: $primary;
  }

  &:not(.active):hover {
    cursor: pointer;
    background: lighten($border, 38%);
    box-shadow: 2px 5px 12px rgba(1,1,1,.1);

    .bmt-file-picker span {
      color: $text;
    }
  }

  .bmt-mini-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  i {
    color: darken($secondary, 10%);
    display: inline-block;
    vertical-align: top;
    margin: 0 .4vmin 0 0;
  }

  .bmt-file-picker {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    input {
      visibility: hidden;
    }

    label {
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      cursor: pointer;
      padding: 0;
      color: $textLight;
      font-size: 1.8vmin;
      line-height: 2vmin;

      span {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.bmt-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 50% !important; 
  height: 10px !important; 
  border: none !important;
  background: lighten($save, 25%) !important;
  border-radius: 4px !important;
  outline: none; 
  padding: 0 !important;
  margin: 2vmin 0 0 !important;
}

.bmt-slider::-webkit-slider-thumb {
  -webkit-appearance: none;  
  appearance: none;
  width: 25px; 
  height: 25px; 
  background: $save;  
  cursor: pointer;  
  border-radius: 100%;
}

.bmt-slider::-moz-range-thumb {
  width: 25px;  
  height: 25px;  
  background: $save;  
  cursor: pointer;  
}

.bmt-form-page {
  margin: 0 0 2vmin;

  &.smaller {
    .form-group {
      width: 50%;
    }
  }

  &.quarter {
    .form-group {
      width: 25%;
    }
  }
}

.bmt-list-view {
  margin: 1vmin 0 0;
  padding: 0;

  li {
    margin: 0;
    padding: 1.5vmin 2vmin;
    border-bottom: 1px solid #eee;
    font-size: 1.7vmin;
    display: flex;
    position: relative;
    align-items: center;

    &.sum-row {
      background: #222;
      background: linear-gradient(darken($primary, 20%), $primary);
      border-radius: 8px;
      color: #fff;
      text-transform: uppercase;
      font-weight: 600;
    }

    .flex {
      flex: 1;

      &.max-width-40 {
        max-width: 40%;
        word-wrap: break-word;
      }

      &.nowrap {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &.bigger {
        flex: 1.5 !important;
        text-align: left !important;
      }

      &.small {
        flex: .5;
        margin: 0 0 0 3vmin;
      }

      &:last-child {
        flex: .3;
        text-align: center;
      }
      
      .icon-info {
        color: $orange;
      }

      .icon-check {
        color: $save;
      }

      .icon-frown {
        color: $redText;
      }

      .list-tag {
        display: inline-block;
        padding: 1vmin;
        border-radius: 8px;
        background: lighten($secondary, 5%);
        margin: .5vmin 0;
        font-size: 90%;

        &.red-bg {
          background: $red;
        }

        &.green-bg {
          background: lighten($primary, 35%);
        }
      }
    }

    .action-btn {
      font-weight: 500;
      font-size: 1.7vmin;
      transition: all .4s ease;
    }

    .more-btn {
      visibility: hidden;
      transition: none;
      padding: .5vmin 1vmin;
    }

    &.no-data {
      &:hover {
        cursor: no-drop;
        
        .more-btn {
          visibility: visible;
        }
      }
    }

    &:not(:first-child):not(.no-action):not(.no-data):hover {
      cursor: pointer;
      background: lighten($primary, 40%);

      .more-btn {
        visibility: visible;
      }
    }

    &:not(.no-header):first-child {
      font-weight: 500;
      color: #111;
      background: $bg;
      border: none;
      border-radius: 8px;
    }

    &.adder-row input {
      border-color: darken($bg, 10%);
      width: 96%;
    }
    
    &:last-child {
      border: none;
    }
  }
}

.share-btn {
  background: $bg;
  margin: 0 !important;
  padding: .75vmin 1.6vmin;
  border-radius: 15px;
  font-size: 1.6vmin;

  &.not-actionable {
    pointer-events: none;
  }
  
  &.shared {
    background: $save;
    color: #fff;

    &:not(.not-actionable):hover {
      background: darken($save, 9%);
    }
  }

  &:not(.not-actionable):hover {
    background: darken($bg, 7%);
  }
}