@import "../../_vars.scss";

.bmt-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(1,1,1,.4);
  z-index: 9999;
  cursor: default;

  &.presentation-modal {
    .bmt-overlay-content {
      width: 90%;
      background: #111;
      min-height: 70vh;
      color: #fff;
    }
  }

  .bmt-overlay-content {
    width: 60%;
    min-height: 20vh;
    max-height: 80vh;
    overflow-y: auto;
    background: #fff;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    box-shadow: 0 4px 8px rgba(1,1,1,.2);  
    padding: 4vmin;  
    color: $text;    

    .form-group {
      width: 100% !important;
    }

    &.static-height {
      height: 80vh;
    }

    .external-input {
      width: 50%;
    }

    &.bigger {
      width: 90%;
      max-height: 90vh;
    }
  }

  .bmt-overlay-header {
    display: flex;
    margin: 0 0 2vmin;

    h1 {
      flex: 1;
    }

    .close-btn {
      color: #666;
      font-size: 3vmin;
      flex: initial;
    }
  }

  .primary-btn {
    margin: 2vmin 0 0;
  }

  input {
    border: 1px solid #eee;
    font-family: inherit;
    font-size: 1.8vmin;
    line-height: 2vmin;
    padding: 1vmin;
    border-radius: 8px;
    outline: none;

    &:focus {
      border-color: $primary;
    }
  }
}