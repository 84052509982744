@import "../../_vars.scss";

.client-cards {
  display: flex;
  flex-wrap: wrap;
  margin: 4vmin 0 0;

  .client-card {
    margin: 0 2% 2% 0;
    width: 18.4%;
    background: #fff;
    color: $text;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(1,1,1,.1);
    padding: 2vmin;
    transition: all .4s ease;
    position: relative;
    text-align: left;
    border: 1px solid #eee;

    &.archived {
      background: #efefef;
    }

    h3 {
      font-weight: 500;
      margin: 0 0 .5vmin;
    }

    h5 {
      color: $textLight;
      margin: .5vmin 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:nth-child(5n) {
      margin: 0 0 2%;
    }

    &:hover {
      cursor: pointer;
      opacity: .9;
      box-shadow: 0 4px 10px rgba(1,1,1,.2);
    }
  }
}